/* @import url("../node_modules/font-awesome/css/font-awesome.min.css"); */

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  /* order: 1px solid transparent; */
  border-left: 1px solid rgb(220, 218, 218);

  line-height: 28px;
  /* padding-left: 17px;
  padding-right: 17px; */
  padding-left: 7px;
  padding-right: 7px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.ag-theme-alpine .ag-cell-inline-editing{
  height: 33px;
}
.ag-input-field-input{
  padding: 0px 2px 0px 2px;
  text-align: inherit;
}

.my-class {
  text-align: center;
}

.my-class-rowspan {  
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}
.my-class-rowspan > .ag-cell-wrapper {
  align-items: flex-start;
}
.my-class-rowspan2 {  
  /* color: white; */
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.alignL .ag-cell-wrapper,
.ag-cell.alignL {
  text-align: left;
  justify-content: flex-start;    
  /* padding-top: 1px; */
}
.alignR .ag-cell-wrapper,
.ag-cell.alignR {
  text-align: right;
  justify-content: flex-end;
  /* padding-top: 1px; */
}
.alignC .ag-cell-wrapper,
.ag-cell.alignC {
  text-align: center;
  justify-content: center;  
  /* padding-top: 1px; */
}
.onclick .ag-cell-wrapper,
.ag-cell.onclick {
  cursor: pointer;
}
.ag-cell.nocheckbox .ag-cell-wrapper .ag-selection-checkbox.ag-invisible {
  display: none;
}
.ag-header-cell.editCell .ag-header-cell-text::before {
  font-family: "FontAwesome";
  /* content: "\f044"; */
  content: "\f040";
  color: rgb(65, 68, 75);
  
  padding-right: 5px;
}

/* 추가 START*/
/* .ag-header-cell.editCellSub{
  color: #ed5d3b;
} */
.ag-header-cell.editCellSub .ag-header-cell-text::before {
  font-family: "FontAwesome";
  content: "\f040";
  color: rgb(65, 68, 75);
  padding-right: 5px;
}
.ag-header-cell.editCellSub .ag-header-cell-text::after {
  font-family: "FontAwesome";
  /* content: "\f044"; */
  content: "*";
  color: #ed5d3b;
  padding-right: 5px;
  padding-top: 0.5em;
  font-size: medium;
}
.ag-header-cell.editCellOnlySub .ag-header-cell-text::after {
  font-family: "FontAwesome";
  /* content: "\f044"; */
  content: "*";
  color: #ed5d3b;
  padding-right: 5px;
  padding-top: 0.5em;
  font-size: medium;
}


/* 추가 END */
.csvButton {
  position: absolute;
  cursor: pointer;
  z-index: 9;
  width: 30px;
  height: 30px;
 
  padding-left: 5px;
}

.prevButton{
  position: absolute;
  cursor: pointer;
  z-index: 9;
  width: 30px;
  height: 20px;
 
  padding-left: 5px;
}
.nextButton{
  position: absolute;
  cursor: pointer;
  z-index: 9;
  width: 30px;
  height: 20px;
 
  padding-left: 5px;
}
/* .csvButton::before  {
  font-family: "FontAwesome";
  content: "\f1c3";
  color: rgb(65, 68, 75);
  
  padding-right: 5px;
} */

/* .loadingMessage::before  {
  font-family: "FontAwesome";
  content: "\f252";
  color: rgb(65, 68, 75);
  
  padding-right: 5px;
} */

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell{
  padding-left: 8px;
  padding-right: 18px;
}
.ag-theme-alpine .ag-ltr .ag-header-select-all{  
  margin-right: 10px;
}




/*  추가  */

/* Header Center정렬 */
.ag-header-cell-label {
	justify-content: center; 
  /* padding-left: 20px; */
  margin-right: -25px;
} 

/* 정렬 */
.ag-text-ar{
  text-align: right;
}

.ag-text-al{
  text-align: left;
}

.ag-text-ac{
  text-align: center;
}


/* .ag-pinned-left-header{
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}
.ag-pinned-left-cols-container{
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}
.ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}
.ag-horizontal-left-spacer{  
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
} */
/* .ag-horizontal-left-spacer{
  overflow: hidden;
} */

.ag-header-group-cell-label{  
  align-items: center;
  justify-content: center;
}

.header-green {
  background-color: #e4e9f5;
  color: black;
}
.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.cell-span {
  background-color: white;
}

.show-name {
  font-weight: bold;
}

.show-presenter {
  font-style: italic;
}


/* ag-grid header :: Table Header with Multiple Rows */
.ag-header-cell-label{
  white-space: pre;
}
.ag-header-cell {
  border: 1px;
  padding-left: 10px;
  padding-right: 0;
}
.ag-header-cell-text {
  /* padding-top: 15px; */
  padding-left: 0;
  padding-right: 0;
  white-space: pre;
  display: inline-block;
  line-height:13px;
  text-align: center;
}


.ag-center-cols-viewport {
  overflow: hidden;
}
.ag-body-horizontal-scroll-viewport{
  overflow-x: auto;
}