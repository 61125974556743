@charset utf-8;
body {
    margin:0;
    padding:0;
    font-style:"NanumSquare";
    font-size:12px;
    line-height:18x;
}
#wrap {
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    
    display:flex;
    flex-flow:row nowrap;
   

}
.container {
    margin:0 auto;
    width:100%;
}
section {
    box-sizing:border-box;
}


