.content_section {
    display: flex; flex-flow:column nowrap; width:100%; height:100%;
    min-width:1000px;
}


/* tit_section 시작 */
/* .content_section .tit_section {
    display: flex; flex-flow:row nowrap; width:100%; 
    height:50px;
    background:#fff;
    border-top:1px solid #BFBDCD; border-bottom:1px solid #BFBDCD;
}
.content_section .tit_section .container {
    display: flex; flex-flow: row nowrap; align-items: center; width:100%; height:100%;
    position: relative;
    
    padding-left: 15px; margin-right: 5px;
}
.content_section .tit_section .container .position_box {
    display: flex; flex-flow: row nowrap; align-items: center; width:60%; height:100%;
} */
.tit_section {
    display: flex; flex-flow:row nowrap; width:100%; 
    /* height:46px; */
    height:50px;
    background:#fff;
    border-top:1px solid #BFBDCD; border-bottom:1px solid #BFBDCD;
}
.tit_section .container {
    display: flex; flex-flow: row nowrap; align-items: center; width:100%; height:100%;
    /* min-width:1400px;  */
    position: relative;
    
    padding-left: 15px; margin-right: 5px;
}
.tit_section .container .position_box {
    display: flex; flex-flow: row nowrap; align-items: center; width:60%; height:100%;
    /* min-width:800px; */
}



.position_list {
    font-size:13px;
    min-width: 121px;
    /* padding-left: 10px; */
}


/* 버튼 스타일 시작 */
#BTs {
    display: flex; flex-flow: row nowrap; justify-content:flex-end;  align-items: center; 
    /* width:40%; */
    height:100%;
    position: absolute; right:0;    
    padding-right: 5px;
}

#BTs button {
    display: flex; flex-flow: row nowrap; justify-content: flex-start; align-items: center; 
    /* width:70px;  */
    height:30px;
    border: 1px solid #B7BCD6; border-radius: 2px;
    background:#fff;
    margin-left:10px;
    font-weight: 600;
    cursor: pointer;
    padding-right: 10px;
}

#BTs button:hover {
    background:#E8ECF5;
}

#BTs button img {
    margin-right:6px; margin-left:8px;
}

#popup_layer {
    display: none;
    /* width:500px;  */
    /* height:50px;  */
        
    /* position: absolute; top:20px; left: 390px;  */
    position: absolute;
    top: 15px; left: 40px;
    
    margin-top: 20px; 
    margin-left: 10px;

    padding: 2px 10px;
    Z-INDEX: 1; 
    background:#fff; 
    border:1px solid #5972C9;    
}

#popup_layer .p_help {
    display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; 
    /* width:98%;  */
    width:100%;     
    height: 100%;
    line-height: 20px; font-size: 13px;
}
/* 버튼 스타일 끝 */

/* tit_section 끝 */



/* search_section 시작 */
.search_section {
    display: flex; 
    /* flex-flow:column nowrap;  */
    width:100%; 
    /* height:80px; */
    /* background:#E2E7F2; */
    background:#f1f3f7;
    /* border-bottom:1px solid #BFBDCD; */
}

.search_section .container {
    display: flex; flex-flow: row nowrap; align-items: center; width:99%; 
    /*min-width:1400px;*/ 
    min-width:500px;    
    height:100%;
}
.search_section .container {
    display: flex; flex-flow: row nowrap; width:100%; height: 100%; 
}
.search_section .container .form_box {
    display: flex; flex-flow: row nowrap; align-items: center; width:100%; 
    
    /* height: 40px;  */
    height: 35px; 
}

/* .content_section .search_section .container {
    display: flex; flex-flow: row nowrap; align-items: center; width:99%; min-width:1400px; height:100%;
}
.content_section .search_section .container form {
    display: flex; flex-flow: column nowrap; width:100%; height: 100%; 
}
.content_section .search_section .container form .form_box {
    display: flex; flex-flow: row nowrap; align-items: center; width:100%; height: 40px; 
} */

/* form 박스 스타일 시작 */
/* form .form_box div { */
.form_box div {
    font-size: 13px;
    border-radius: unset;
}

/*form .form_box .h_Label1R { */
.form_box .h_Label1R {
    /* display: flex; flex-flow: row nowrap; justify-content: flex-end; align-items: center;  */

    /* background: red; */
    float:left;
     width:80px; 
    /* height:30px; */

    /*margin-left: 25px;
    margin-right:6px;
    padding: 7px 2px;
    text-align: right;
    vertical-align: baseline;
    font-family: '나눔고딕', 'NanumGothic', 'sans-serif';        
    min-width: 67px;*/
    
    /*margin-left: 18px;
    margin-right:6px;
    padding: 7px 2px;
    text-align: right;
    vertical-align: baseline;
    font-family: '나눔고딕', 'NanumGothic', 'sans-serif';        
    min-width: 55px*/

    margin-left: 6px;
    margin-right:6px;
    padding: 7px 2px;
    text-align: right;
    vertical-align: baseline;
    font-family: '나눔고딕', 'NanumGothic', 'sans-serif';        
    min-width: 90px

  
}

.form_box .h_Label1L_insert {
    /* font-weight: bold; */
    color: #2969dc;
    font-family: "NanumSquare", "나눔고딕", "NanumGothic", sans-serif;
}
.form_box .h_Label1L_insert1 {
    /* font-weight: bold; */
    color: #2969dc;
    font-family: "NanumSquare", "나눔고딕", "NanumGothic", sans-serif;
}
.form_box .h_Label1R_insert {
    /* font-weight: bold; */
    color: #2969dc;
    font-family: "NanumSquare", "나눔고딕", "NanumGothic", sans-serif;
}
.form_box .h_Label1R_insert1 {
    /* font-weight: bold; */
    color: #2969dc;
    font-family: "NanumSquare", "나눔고딕", "NanumGothic", sans-serif;
}

.form_box .h_Label1L_insert::before {    
    /* content: "\f02c"; */
    content: "\f040";
    padding-right: 5px;
     
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:#000;
}
.form_box .h_Label1R_insert::before {    
    /* content: "\f02c"; */
    content: "\f040";
    padding-right: 5px;
     
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:#000;
}

/* form .form_box .h_Label1L { */
.form_box .h_Label1L {
    /* display: flex; flex-flow: row nowrap; align-items: center;  */
    float:left;
    width:200px; 
    /* height:30px; */
}

.form_box .h_Label3L {
    /* display: flex; flex-flow: row nowrap; align-items: center;  */
    float:left;
    width:265px; 
    /* height:30px; */
}

form .form_box .h_Label1L .k_combo_small {
    width:40%;
}

form .form_box .h_Label1L select {
    width:100%; height:100%;
    border:1px solid #CECDD9;
}

form .form_box .h_Label2R {
    display: flex; flex-flow: row nowrap; justify-content: flex-end; align-items: center; width:80px; height:30px;
    margin-right:6px;
}

form .form_box .h_Label2L {
    display: flex; flex-flow: row nowrap; align-items: center; width:200px; height:30px;
}

form .form_box .h_Label2L select {
    width:100%; height:100%;
    border:1px solid #CECDD9;
}

form .form_box .h_Label2L input {
    width:100%; height:28px;
    border:1px solid #CECDD9;
    padding-left:5px;
}

form .form_box .h_Label3L  { /*검색조건에서 데이터 입력 .h_Label1R */
    float:left;
    width:100px; 
}

form .form_box .h_Label3L select { /*검색조건에서 데이터 입력*/
    width:50%; height:100%;
    border:1px solid #CECDD9;
}
.form_box .h_Label3R {
    float:left;
    margin-left: 6px;
    margin-right:6px;
    padding: 7px 2px;
    text-align: right;
    vertical-align: baseline;
    font-family: '나눔고딕', 'NanumGothic', 'sans-serif';        
    min-width: 90px;
    color: red;
    font-weight: bold;
}
/* form 박스 스타일 끝 */

/* search_section 끝 */




/* cont_section 시작 */

.cont_section {
    display: flex; flex-flow:column nowrap; width:100%; height: 100%; 
    /* background: #E2E7F2; */    
    /* padding: 10px; */
    padding: 0px 10px 10px 10px;

    
    /* height: -moz-calc(100% - (20px + 30px));
    height: -webkit-calc(100% - (20px + 30px));
    height: calc(100% - (20px + 30px)); */
}

.cont_section .container {
    display: flex; flex-flow: row nowrap; width:99%; 
    /*min-width:1400px;*/ 
    min-width:500px; 
    height:100%; 
    /* min-height:600px;    */   
}


.cont_section .container .grid_wrap {
    width:35%; min-width:600px; height: 91.5%;
    
}

.cont_section .container .grid_wrap .grid_area {
    margin-top:10px; margin-right:10px;
    width:98.5%; height:98%;
    
}

.cont_section .container .grid_wrap .grid_area .grid_area_cont {
    width:100%; height:100%;
    background: #E2E7F2;
    border-top:1px solid #736F8B; border-bottom:1px solid #736F8B; 
}




.cont_section .container .input_box_wrap {
    display:flex; flex-flow:column nowrap;   width:65%; min-width:600px; height: 100%;
}

table.input_box td {
    border-spacing: 30px;
    /* border:1px solid #CECDD9; */
}

.cont_section .container .input_box_wrap .input_box {
    width:100%; 
    margin-top:10px;
    border:1px solid #736F8B; 
    
}


.standard_tr {   
    height:30px;
}


.standard_tr .t_head {
    text-align: center; vertical-align: middle;
    font-size:16px; font-weight: 400; font-family: Arial, Helvetica, sans-serif;
}


.standard_line {
    height:3px;
    border:1px solid red;
}

.standard_tr .k_label13R .k_inputChkL_M {
    width:100%; height:30px;
}



.standard_tr2 {   
    height:39px;
}


.standard_tr2 .t_head {
    text-align: center; vertical-align: middle;
    font-size:16px; font-weight: 400; font-family: Arial, Helvetica, sans-serif;
}


.standard_tr2 .k_label13R .k_inputChkL_M {
    width:100%; height:30px;
}


.uno_tr {
    cursor: default;
}
.uno_tr .t_head {
    background:#E2E7F2;
    border:1px solid #CECDD9;
    
}

.uno_tr .k_label13R {
    background:#E2E7F2;
    border:1px solid #CECDD9;
    text-align:right; 
    padding-right:5px;
    
} 
.k_label18L {
    border:1px solid #CECDD9;
    height:30px;
} 
.k_label18L input {
    width:85%; height:30px;  
    
}

.k_label18L select {
    width:100%; height:30px; 
}

.no_point {
    color: #FF37B6;
}


.dos_tr {
    cursor: default;
}
.dos_tr .t_head {
    background:#E1E4F4;
    border:1px solid #CECDD9;
    
}

.dos_tr .k_label13R {
    background:#E1E4F4;
    border:1px solid #CECDD9;
    text-align:right; 
    padding-right:5px;
    
} 

.tres_tr {
    cursor: default;
}
.tres_tr .t_head {
    background:#D8E1F1;
    border:1px solid #CECDD9;
    
}

.tres_tr .k_label13R {
    background:#D8E1F1;
    border:1px solid #CECDD9;
    text-align:right; 
    padding-right:5px;
    
} 

.k_label13R_f {
    background:#fff;
    text-align: center; vertical-align: middle;
}

.k_label18L_f {
    background:#fff;
    text-align: center; vertical-align: middle;
}


.radio_box {
    height:30px;
    display: flex; flex-flow: row nowrap;
}

.radio_box label {
    height:30px;
    width:30%;
    display: flex; flex-flow: row nowrap; justify-content: center; align-items: center;
    
    cursor: pointer;
}



.k_label18L label input {  
    
    height: 15px; 
    background:red;
    vertical-align: middle;
    display: flex; flex-flow: row nowrap; justify-content: center; align-items: center;
}

.radio_color {
    font-weight: 600;
}

.radio_color01 {
    color:#FF3D3D;
    font-weight: 900;
}

.radio_color02 {
    color:#2AA118;
    font-weight: 900;
}

.radio_color03 {
    color:#304CB7;
    font-weight: 900;
}

.radio_color04 {
    color:#fd6900;
    font-weight: 900;
}
.radio_color05 {
    color:#000000;
    font-weight: 900;
}

/* cont_section 끝 */




/* grid_type 시작 */
/* 1번 타입 그리드 css START --- */
.cont_section .container02 {
    display: flex; flex-flow:column nowrap; 
    /* width:99%;  */
    width:100%; 
    /* min-width:1400px;  */
    min-width:500px;   
    min-height:300px;
    height:100%;
}

.cont_section .container02 .grid_type01 {
    width:100%;  
    /* height: 51%;   */
    /* height: 50%;   */
    height: 100%;

    /* height: -moz-calc(49% - (20px + 30px));
    height: -webkit-calc(49% - (20px + 30px));
    height: calc(49% - (20px + 30px)); */
}

.cont_section .container02 .grid_type01 .grid_wrap01 {
    /* margin-top:10px;  */
    width:100%; height:100%;
    /* border-top:1px solid #736F8B; border-bottom:1px solid #736F8B;  */
    /* background: #E2E7F2;  */
    /*그리드 코드 삽입 후 배경컬러는 주석처리해주세요*/    
}

.cont_section .container02 .grid_type01 .grid_wrap01 .grid_area  {
    width:100%; height:100%;
}
.cont_section .container02 .grid_type01 .grid_wrap01 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}


.cont_section .container02 .grid_type01 .grid_wrap01 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    /* border-bottom:1px solid #736F8B;  */
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}
.cont_section .container02 .grid_type01 .grid_wrap01 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}
/* 1번 타입 그리드 css --- END */



/* 2번 타입 그리드 css START --- */
.cont_section .container02 .grid_type02 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* width:65%;  */    
    width: 100%;
    min-width:600px; height: 100%;
    
    margin-top: 10px;
}

.cont_section .container02 .grid_type02 .grid_wrap02 {
    display: flex; flex-flow: row nowrap; 
    width:49.7%;  height: 100%;  
    margin-right:10px;

}

.cont_section .container02 .grid_type02 .grid_wrap02 .grid_area {    
    width:100%; height:100%;
    /* border:1px solid #736F8B;  */
    background: #fff;
    display: flex; flex-flow:column nowrap;     
}

.cont_section .container02 .grid_type02 .grid_wrap02 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    /* border-bottom:1px solid #736F8B;  */
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}
.cont_section .container02 .grid_type02 .grid_wrap02 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}

.cont_section .container02 .grid_type02 .grid_wrap02 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}
/* 2번 타입 그리드 css --- END */



/* 3번 타입 그리드 css START --- */
.cont_section .container02 .grid_type02 .grid_wrap03 {
    display: flex; flex-flow: row nowrap; 
    width:49.7%;  height: 100%;  
    /* background: blue; */
}


.cont_section .container02 .grid_type02 .grid_wrap03 .grid_area {    
    width:100%; height:100%;
    /* border:1px solid #736F8B;  */
    background: #fff;
    display: flex; flex-flow:column nowrap;     
}

.cont_section .container02 .grid_type02 .grid_wrap03 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    /* border-bottom:1px solid #736F8B;  */
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}

.cont_section .container02 .grid_type02 .grid_wrap03 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}

.cont_section .container02 .grid_type02 .grid_wrap03 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}
/* 3번 타입 그리드 css --- END */



/* 4번 타입 그리드 css START --- */
.cont_section .container02 {
    display: flex; flex-flow:column nowrap; 
    /* width:99%;  */
    width:100%; 
    /* min-width:1400px;  */
    min-width:500px;
    min-height:300px;
    height:100%;    
    padding-top: 10px;
}

.cont_section .container02 .grid_type04 {
    width:100%;  
    /* height: 44%;  */
    height: 49%; 
    margin-top:10px; 
}

.cont_section .container02 .grid_type04 .grid_wrap04 {
    /* margin-top:10px;  */
    width:100%; height:100%;
    /* border-top:1px solid #736F8B; border-bottom:1px solid #736F8B;  */
    /* background: #E2E7F2;  */
    /*그리드 코드 삽입 후 배경컬러는 주석처리해주세요*/    
}

.cont_section .container02 .grid_type04 .grid_wrap04 .grid_area  {
    width:100%; height:100%;
}
.cont_section .container02 .grid_type04 .grid_wrap04 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}


.cont_section .container02 .grid_type04 .grid_wrap04 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    /* border-bottom:1px solid #736F8B;  */
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}
.cont_section .container02 .grid_type04 .grid_wrap04 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}
/* 4번 타입 그리드 css --- END */



/* NEW 5번 타입 그리드 css START --- */
.cont_section > .container03 {
    display: flex; flex-flow: row nowrap;
    /* width: 99%; */
    width:100%; 
    /* min-width: 1400px; */
    min-width: 500px;
    height: 100%;
    min-height: 600px;
}

.cont_section > .container03 .grid_type05 {    
    /* width:100%;   */    
    width: 35%;
    height: 100%;  
    min-width: 600px;
    margin-right: 10px;
}

.cont_section > .container03 .grid_type05 .grid_wrap05 {
    /* margin-top:10px;  */
    width:100%; height:100%;
}

.cont_section > .container03 .grid_type05 .grid_wrap05 .grid_area  {
    width:100%; height:100%;
    margin-top:0px;
}
.cont_section > .container03 .grid_type05 .grid_wrap05 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}


.cont_section > .container03 .grid_type05 .grid_wrap05 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}
.cont_section > .container03 .grid_type05 .grid_wrap05 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}



.cont_section > .container03 .grid_type06 {
    display: flex;
    flex-flow: column nowrap;
    width: 65%;
    min-width: 600px;
}

.cont_section > .container03 .grid_type06 .grid_wrap06 {
    /* margin-top:10px;  */
    width:100%; height:100%;
}

.cont_section > .container03 .grid_type06 .grid_wrap06 .grid_area  {
    width:100%; height:100%;
}
.cont_section > .container03 .grid_type06 .grid_wrap06 .grid_area .grid_cont_box {
    width:100%; height: 100%;   
}

.cont_section > .container03 .grid_type06 .grid_wrap06 .grid_area .grid_tit_box {
    width:100%; height:40px;
    display: flex; flex-flow: row nowrap; 
    position: relative;
    background: #E2E7F2;
    
    border-top: 1px solid #bfbdcd;
    border-left: 1px solid #bfbdcd;
    border-right: 1px solid #bfbdcd;
}
.cont_section > .container03 .grid_type06 .grid_wrap06 .grid_area .grid_tit_box .grid_tit {
    width:60%; height:100%;
    display: flex; flex-flow: row nowrap;  align-items: center; 
    font-weight: 600; font-size: 13px;
    padding-left:5px;
}
/* NEW 5번 타입 그리드 css --- END */

/* grid_type 끝 */






/* 버튼 스타일 시작 */
#BTs02 {
    display: flex; flex-flow: row nowrap; justify-content:flex-end;  align-items: center; width:40%;  height:100%;
    position: absolute; right:2px;
}

#BTs02 button {
    display: flex; flex-flow: row nowrap; justify-content: flex-start; align-items: center; width:70px; height:30px;
    border: 1px solid #B7BCD6; border-radius: 2px;
    background:#fff;
    margin-left:10px;
    font-weight: 600;
    cursor: pointer;
}

#BTs02 button:hover {
    background:#E8ECF5;
}

#BTs02 button img {
    margin-right:6px; margin-left:8px;
}

#BTs02 .btn_L {
    width:120px; height:30px;
}

/* 버튼 스타일 끝 */




/* 추가 */
.k_inputChkL_M {
    text-transform: uppercase;
    text-align:left;
    ime-mode:disabled; 
}

.k_inputChkC_M {
    text-transform: uppercase;
    text-align:center;
    ime-mode:disabled; 
}

.k_inputChkR_M {
    text-transform: uppercase;
    text-align:right;
    ime-mode:disabled; 
}

.k_inputChkNumL {
    ime-mode:disabled;
    text-align:left;
}

.k_inputChkNumC {
    ime-mode:disabled;
    text-align:center;
}

.k_inputChkNumR {
    ime-mode:disabled;
    text-align:right;
}

.k_inputChkL {
    text-transform: uppercase;
    text-align:left; padding:2px;
}

.k_inputChkC {
    text-align:center; padding:2px;
}

.k_inputChkR {
    text-align:right; padding:2px;
}

.info_tr{
    border: 0px;
    text-align: right;
    font-weight: bold;
}


/* InputBox 입력데이터 CSS */
.k_input > .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
}