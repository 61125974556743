@charset utf-8;
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 13px;
    vertical-align: baseline;
    /* font-family: "NanumSquare", "나눔고딕", "NanumGothic", sans-serif; */
    font-family: "malgun gothic", Dotum;
}



@font-face {
    font-family: "ChosunBg";
    font-weight: 800;
    /* src: url(../font/ChosunBg.TTF) format("truetype"); */
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunBg.woff') format('woff')
       , url(../font/ChosunBg.TTF) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "ChosunGu";
    font-weight: 800;
    /* src: url(../font/ChosunGu.TTF) format("truetype"); */
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGu.woff') format('woff')
        ,url(../font/ChosunGu.TTF) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "ChosunGuTest";
    font-weight: 800;
    src: url(../font/ChosunGu.TTF) format("truetype");
    /* src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGu.woff') format('woff'); */
    font-display: block;
}
@font-face {
    font-family: "NanumGothic";
    font-weight: 400;
    src: url(../font/NanumGothic.eot);
    src: url(../font/NanumGothic.eot?#iefix) format("embedded-opentype") url(../font/NanumGothic.woff) format("woff");
}
@font-face {
    font-family: "NanumGothicB";
    font-weight: 600;
    src: url(../font/NanumGothicExtraBold.eot);
    src: url(../font/NanumGothicExtraBold.eot?#iefix) format("embedded-opentype") url(../font/NanumGothicExtraBold.woff) format("woff");
}

@font-face {
    font-family: "NanumSquare";
    font-weight: 400;
    src: url(../font/NanumSquareR.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "NanumSquare";
    font-weight: 700;
    src: url(../font/NanumSquareB.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "NanumSquare";
    font-weight: 800;
    src: url(../font/NanumSquareEB.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "NanumSquare";
    font-weight: 300;
    src: url(../font/NanumSquareL.ttf) format("truetype");
    font-display: block;
}

@font-face {
    font-family: "GmarketSansMedium";
    font-weight: 600;
    src: url(../font/GmarketSansMedium.woff) format("woff");
    font-display: block;
}   


/* @font-face {
    font-family: "GmarketSansBold";
    font-weight: bold;
    src: url(../font/GmarketSansBold.otf) format("otf");
    font-display: block;
}   */
   
@font-face {
    font-family: 'GmarketSansLight';
    font-weight: normal;
    font-style: normal;
    src: url(../font/GmarketSansLight.woff) format('woff');
    font-display: block;
}   
@font-face {
    font-family: "GmarketSansBold";
    font-weight: bold;
    src: url(../font/GmarketSansTTFBold.ttf) format("truetype");
    font-display: block;
}

@font-face {
    font-family: "PretendardExtraLight";
    font-weight:lighter;
    src: url(../font/Pretendard-ExtraLight.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "PretendardLight";
    font-weight:lighter;
    src: url(../font/Pretendard-Light.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "PretendardRegular";
    font-weight:lighter;
    src: url(../font/Pretendard-Regular.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "PretendardMedium";
    font-weight: normal;
    src: url(../font/Pretendard-Medium.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "PretendardSemiBold";
    font-weight: normal;
    src: url(../font/Pretendard-SemiBold.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: "PretendardBold";
    font-weight: bold;
    src: url(../font/Pretendard-Bold.ttf) format("truetype");
    font-display: block;
}
/* //웹폰트 호출 
@font-face {
    font-family: 'GmarketSansBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */
/* @font-face {
    font-family: "HY헤드라인M";
    font-weight: 600;
    src: url(../font/HY헤드라인M.eot);
    src: url(../font/HY헤드라인M.eot?#iefix) format("embedded-opentype") url(../font/HY헤드라인M.woff) format("woff");
} */
@font-face {
    font-family: "HY헤드라인M";
    font-weight: bold;
    src: url("../font/HY헤드라인M.eot") format('eot'),
    url("../font/HY헤드라인M.woff") format('woff'),
    url("../font/HY헤드라인M.ttf") format('truetype');
}



/* @font-face {
    font-family: "NanumGothic";
    font-weight: 600;
    src: url(../font/NanumGothicExtraBold.eot);
    src: url(../font/NanumGothicExtraBold.eot?#iefix) format("embedded-opentype") url(../font/NanumGothicExtraBold.woff) format("woff");
} */


@font-face {
    font-family: "3of9Barcode";

    src: url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.eot");
    src: url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.svg#F3 of 9 Barcode")format("svg");
    src: url("../font/3 of 9 barcode.TTF") format("truetype");

    /* src: url("../font/3 of 9 barcode.TTF") format("truetype");
    src: url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.eot");
    src: url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/7a3abce9f43e0b74320df47238a0460c.svg#F3 of 9 Barcode")format("svg"); */
    font-display: block;
}
.barcode {
    font-family: "3of9Barcode";
}

@font-face {
    font-family: "iQsCode128";

    src: url("../font/iQsCode128.ttf") format("truetype");
    font-display: block;
}
.barcode128 {
    font-family: "iQsCode128";
}
/* 폰트 사이즈 폰트 페밀리 수정 20190930 */

html {
    height: 100%;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}
body {
    height: 100%;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}
article,
aside,
figcation,
figure,
footer,
header,
nav,
section {
    display: block;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #3d3d3d;
}
img {
    border: 0;
    vertical-align: top;
}
table {
    border-collapse: separate;
    border-spacing: 3px;
}

form {
    margin: 0;
    padding: 0;
    border: 0;
}

input {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0 !important;
}

select {
    outline: 0 !important;
}

button {
    outline: none;
}

/* Popup CSS */
.MuiBox-root {
    border: 3px solid #b7bcd6;
}