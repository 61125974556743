.notfound_main{
    transition: all 0.6s;
}

.notfound_main {
    height: 100%;
}

.notfound_div{
    font-family: 'Lato', sans-serif;
    color: #888;
    margin: 0;
    display: table;
    width: 100%;
    height: 70vh;
    
    text-align: center;
}

.fof_notfound{
    display: table-cell;
    vertical-align: middle;
    background-color: #e5e8ed;
}

.fof_notfound h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

.notfound_btn{
    float: left;
    font-family: 'GmarketSansLight';
    font-weight: bold;
    width: 222px;
    height: 45px;
    border-radius: 40px;
    background-color: #222339;
    color: #ffffff;
    padding-top:7px;
    font-size: 15pt;
    cursor: pointer;
}

.notfound_mid{
    font-family:'GmarketSansLight';
    color:#858585;
    font-size: 18pt;
    text-align: left;
    letter-spacing: -2px;
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}