.custom-tooltip {
  position: absolute;
  border-radius: 10px;
  left: 0px;
  top: 0px;
  /* border: 1px solid gray; */
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  transform: translateY(-10%);
  letter-spacing: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 10px;
  display: inline-block;
  transition: opacity 1s;
  pointer-events: none;
  /* background-color: white;   */
  overflow: visible;
  
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 8px;
  white-space: nowrap;
}
