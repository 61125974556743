

.content_section {
    width:100%;
    height:100%;

    background: #fff;

    display: flex;
    flex-flow:row nowrap;
}


.content_section .main_dash_section {
    display: flex;
    flex-flow:column nowrap;

    width:auto;
    min-width:1000px;
    flex:8;

}

.content_section .main_dash_section .container {
    display: flex;
    flex-flow:column nowrap;

    justify-content: space-between;
    align-items: center;

    width:93%;
    height:100%;

}


.content_section .main_dash_section .container .dash_title {
    width:100%;
    height:33%;

    /* background:skyblue; */

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;

    /* border:1px solid salmon; */
    

}

.content_section .main_dash_section .container .dash_title .dash_p01 {
    width:100%;
    height:7%;

    display: flex;
    flex-flow: row nowrap;

    font-size:1.8rem;
    font-weight:900;

    margin-top:15px;

}

.content_section .main_dash_section .container .dash_title .dash_imgbox {
    background:#E2E7F3;

    width:100%;
    height:65%;
    border-radius: 10px;

    display: flex;
    flex-flow: row nowrap;
    
    position: relative;

}
.content_section .main_dash_section .container .dash_title .dash_imgbox .jefe_img {
    width:280px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    /* background:red; */

    position: absolute;

    bottom:0;
    left:11.5%;


}

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox {
    width:50%;
    height:50%;
    margin-left:50px;

    /* background:blue; */

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    


    position: absolute;

    top:50%;
    left:37%;
    transform: translate(0,-50%);

}


/*  데시보드 폰트 시작 */

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox .dash_cont_p00 {
    font-size: 1.5rem;
    font-weight:400;
    line-height: 3.5rem;
}

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox .dash_cont_p01 .span_p01 {
    font-size: 2rem;
    font-weight:600;

    color:#334796;
}

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox .dash_cont_p01 .span_p02 {
    font-size: 2.3rem;
    font-weight:600;

    color:#334796;

}

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox .dash_cont_p02 {
    position: relative;

}

.content_section .main_dash_section .container .dash_title .dash_imgbox .dash_pbox .dash_cont_p03 {
    font-size: 1.2rem;
    font-weight:400;

    position: absolute;

    right:18%;
    bottom:-25%;


}

/*  데시보드 폰트 끝 */



/* 데시보드 게시판 리스트 시작 */


.content_section .main_dash_section .container .list_type {
    width:100%;
    height:28%;

    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;

}

.content_section .main_dash_section .container .list_type01 {

    /* background:pink; */

}






/* 데시보드 게시판 리스트 시작 */



.content_section .main_dash_section .container .list_type .list_box {
    width:47%;
    height:100%;

    display:flex;
    flex-flow:column nowrap;

    /* border:1px solid orange; */
   
}


.content_section .main_dash_section .container .list_type .list_box .list_title {
   
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;
    
    /* font-size:1.4rem;
    font-weight:900; */

    width:100%;
    height:15%;

    flex:1.5;

    border-bottom: 1.5px solid #868B97;
}


.content_section .main_dash_section .container .list_type .list_box .list_title .list_tit_p01 {
    
    font-size:1.6rem;
    font-weight:600;

}


.content_section .main_dash_section .container .list_type .list_box .list_title .list_tit_plus_btn {
     
    width:65px;
    height:65%;

    background:#fff;
    border: 1px solid #919191;
}

.content_section .main_dash_section .container .list_type .list_box .list_title .list_tit_plus_btn a {
    width:100%;
    height:100%;

    font-size:1rem;
    font-weight: 600;
    color:#919191;
}

.content_section .main_dash_section .container .list_type .list_box .list_title .list_tit_plus_btn:hover {
     
    background:#fff;
    border: 1px solid #334796;
}

.content_section .main_dash_section .container .list_type .list_box .list_title .list_tit_plus_btn a:hover {
 
    color:#334796;
    font-weight: 600;
}







.content_section .main_dash_section .container .list_type .list_box .list_cont {
   
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    
    font-size:1.4rem;
    font-weight:900;

    width:100%;
    flex:8.5;
   
}

.content_section .main_dash_section .container .list_type .list_box .list_cont .list_cont_box {
    width:100%;
    height:100%;

    display: flex;
    flex-flow:column nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-grow:1;
}

.content_section .main_dash_section .container .list_type .list_box .list_cont .list_cont_box .list_line {
    width:100%;
    height:100%;

    display: flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #DCDCDC;
   
}


.content_section .main_dash_section .container .list_type .list_box .list_cont .list_cont_box .list_line .list_p01 a {

    font-size:1.2rem;
}


.content_section .main_dash_section .container .list_type .list_box .list_cont .list_cont_box .list_line .list_psmall {

    font-size:1rem;
    color:#343435;
    font-weight: 400;
}




/* 리스트 중요 폰트 컬러 */
.content_section .main_dash_section .container .list_type .list_box .list_cont .list_cont_box .list_line .list_p01 .list_p_red {

    color:#F2594B;
}


.content_section .main_dash_section .container .list_type02 {

    /* background:salmon; */

    margin-bottom:3%;
}

.content_section .main_dash_section .container .list_type02 .list_box .list_title02 {

   border-bottom:none;  
}


.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 {

    border-bottom:none;  
 
    /* background:powderblue; */

    display:flex;
    flex-flow:column nowrap;
    justify-content: flex-start;
    align-items: center;

   
 }
 

.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 .list_fq_box {
  
    display:flex;
    flex-flow:row nowrap;
    justify-content: flex-start;
    align-items: center;

    /* background:olivedrab; */

    width:100%;
    height:30%;

    margin-bottom:2%;
   
 }

.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 .list_fq_box:last-child {
    margin-bottom:0;
 }



.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 .list_fq_box  .list_fq_box01 {
     width:23.5%;
     height:100%;

     margin-right:2%;
     
     border:1px solid #D1D5DE;

     cursor: pointer;

 }

.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 .list_fq_box  .list_fq_box01:hover {
   
    border: 1px solid #334796;
} 

.content_section .main_dash_section .container .list_type02 .list_box .list_cont02 .list_fq_box  .list_fq_box01:last-child {

    margin-right:0;
    


}

/* 마이페이지 CSS */


.content_section .mypage_dash_section {
    display: flex;
    flex-flow:column nowrap;
    
    width:405px;
    min-width:300px;
    flex:2;

    background:#F3F5F7;

    border-left:1px solid #E2E4EA;
}


.content_section .mypage_dash_section .container {
    display: flex;
    flex-flow:column nowrap;
    justify-content: space-around;
    
    width:90%;
    height:100%;
}


.content_section .mypage_dash_section .container .mypage_box01 {
    width:100%;
    height:28%;

    /* background:blue; */
    margin-bottom:2%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}


.content_section .mypage_dash_section .container .mypage_box02 {
    width:100%;
    height:18%;

    margin-bottom:2%;

    /* background:olivedrab; */
    
}


.content_section .mypage_dash_section .container .mypage_box03 {
    width:100%;
    height:50%;

    /* background:salmon; */
    
}


/* 마이페이지 박스01*/


.content_section .mypage_dash_section .container .mypage_box01 .mypage_img {
   position: relative;

   margin-bottom:14px;
}

.content_section .mypage_dash_section .container .mypage_box01 .mypage_setting_btn {
    position: absolute;
    right:0;
    top:75%;

    cursor: pointer;

 }

.content_section .mypage_dash_section .container .mypage_box01 .my_info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
 }

.content_section .mypage_dash_section .container .mypage_box01 .my_info .my_p {
    line-height: 2.5rem;
 }

.content_section .mypage_dash_section .container .mypage_box01 .my_info .my_position {
    font-size: 1.2rem;
 }

.content_section .mypage_dash_section .container .mypage_box01 .my_info .my_name {
    font-size: 2rem;
    font-weight: 600;

}

.content_section .mypage_dash_section .container .mypage_box01 .my_info .my_name span {
    font-size: 2rem;
    font-weight: 600;

}




/* 마이페이지 박스02 */

.mypage_box02 .list_box {


    width:100%;
    height:100%;

    display:flex;
    flex-flow:column nowrap;
   
}

.mypage_box02 .list_box .list_title {
   
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;
    
    /* font-size:1.4rem;
    font-weight:900; */

    width:100%;
    height:25%;

    flex:2;

    border-bottom: 1.5px solid #868B97;
}


.mypage_box02 .list_title .list_tit_p01 {
    
    font-size:1.5rem;
    font-weight:600;

   

}


.mypage_box02 .list_title .list_tit_plus_btn {
     
    width:65px;
    height:77%;

    background:#fff;
    border: 1px solid #919191;
}

.mypage_box02 .list_title .list_tit_plus_btn a {
    width:100%;
    height:100%;

    font-size:1rem;
    font-weight: 600;

    color:#919191;
}

.mypage_box02 .list_title .list_tit_plus_btn:hover {
     
    background:#fff;
    border: 1px solid #334796;
}

.mypage_box02 .list_title .list_tit_plus_btn a:hover {
 
    color:#334796;
    font-weight: 600;
}


.mypage_box02 .list_box .list_cont {
   
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;

    background:#fff;
    
    

    width:100%;
    flex:8;
   
}

.mypage_box02 .list_box .list_cont .list_cont_box {
    width:100%;
    height:100%;

    display: flex;
    flex-flow:column nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-grow:1;
}

.mypage_box02 .list_box .list_cont .list_cont_box .list_line {
    width:100%;
    height:100%;

    display: flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #DCDCDC;
   
}


.mypage_box02 .list_box .list_cont .list_cont_box .list_line .list_p01 a {

    font-size:1.1rem;
    font-weight: 400;
    padding-left:5px;
}


.mypage_box02 .list_box .list_cont .list_cont_box .list_line .list_psmall {

    font-size:1rem;
    color:#343435;
    font-weight: 400;
}


/* 마이페이지 박스03 */


.mypage_box03 .list_box {


    width:100%;
    height:100%;

    display:flex;
    flex-flow:column nowrap;
    justify-content: flex-start;
   
}

.mypage_box03 .list_box .list_title {
   
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;
    
    width:100%;
    height:10%;

    
}


.mypage_box03 .list_title .list_tit_p01 {
    
    font-size:1.5rem;
    font-weight:600;

   

}

.mypage_box03 .calendar_box01 {
    width:100%;
    height:50%;

    background:#fff;
    border: 1px solid#D1D5DE;

}


.mypage_box03 .calendar_box02 {
    width:100%;
    height:35%;
    margin-top:5%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    background:#fff;
    border:1px solid #D1D5DE;
}

.mypage_box03 .calendar_box02 .list_title img {
    
    margin-right:5px;
    margin-top:2px;

    width:16px;
    
    
}

.mypage_box03 .calendar_box02 .list_title {

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    width:96%;
    height:20%;

    border-bottom: 1px solid #919191;
}

.mypage_box03 .calendar_box02 .list_title .list_tit_p01 {
    font-size:1.1rem;
    
}

.mypage_box03 .calendar_box02 .list_title .list_tit_plus_btn {
    position: absolute;
    right:0;

    width:55px;
    height:75%;

    background:#fff;
    border: 1px solid #919191;
}

.mypage_box03 .calendar_box02 .list_title .list_tit_plus_btn a {
    
    color:  #919191;
}


.mypage_box03 .calendar_box02 .list_title .list_tit_plus_btn a:hover {
    
    color:#334796;
}

.mypage_box03 .calendar_box02 .list_title .list_tit_plus_btn:hover {
    position: absolute;
    right:0;

    width:55px;
    height:75%;

    background:#fff;
    border: 1px solid #334796;
    
}

.mypage_box03 .calendar_box02 .calendar_cont_box {

    width:100%;
    height:80%;

    display: flex;
    flex-flow: column nowrap;
}

.mypage_box03 .calendar_box02 .calendar_cont_box ul {

    width:100%;
    height:100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.mypage_box03 .calendar_box02 .calendar_cont_box ul li {
 
    width:94%;
    height:23.5%;
    margin-bottom:2px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    
}
.mypage_box03 .calendar_box02 .calendar_cont_box ul li:last-child {
 
    margin-bottom:0;    
}


.mypage_box03 .calendar_box02 .calendar_cont_box ul li span {
 
   width:4px;
   height:4px;

   border-radius: 50%;

   background: #334796;
   margin-right:7px;


    
}