html,
body {
  padding: 0;
  margin: 0;
  font-family: NanumSquare, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #E5E8ED;
}

a {
  color: inherit;
  text-decoration: none;
}

 * {  
    box-sizing: border-box;  
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    vertical-align: baseline;
    font-family: 'NanumSquare', 'NanumGothic', sans-serif;

}



.wrap {
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    display:flex;
    flex-flow:column nowrap;
}

.login_section {
    width:100%;
    height:100%;
    background:#E5E8ED;
    position: absolute;
}

.login_box {
    width: 1100px;
    height: 500px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:flex;
    flex-flow:row nowrap;
}

.login_img{
  width: 257px;
  margin-left: 145px;
  margin-top: 0px;
}

/* Login_left PART Start */
.login_left {
    width:55%;
    height:auto;
  
}
.login_left p:nth-child(0) {
    /* color: #A5A5A5;  */
    /* color: #323A45; */
    background:rgba(0, 0, 0,0.6);
    padding-left:30px;
    height: 31px;
}
.login_left p:nth-child(1) {
  /* color: #A5A5A5;  */
  background:rgba(0, 0, 0,0.6);
  padding-left:30px;
  height: 32px;
}
.login_left p:nth-child(2) {
  /* color: #A5A5A5;  */
  background:rgba(0, 0, 0,0.6);
  padding-left:30px;
  height: 33px;
}
.login_left p:nth-child(3) {
    border-top: solid 1px #E5E8ED;
    width: 245px;
    height: 45px;
    padding-left: 5px;
    margin-left: 25px;
    margin-top: 5px;
    padding-top: 5px;
}
.login_left p:nth-child(4) {
  /* color: #A5A5A5;  */
  /* color: #323A45; */
  padding-left:30px;
  height: 250px;
}
.login_left p:first-child {
    padding-top:20px;
    height: 110px;
}
.login_left .hd_p:first-child {
    /* color: #9386C6; */
    font-weight: 600;
    height: 110px;
    font-size: 12pt;
}
.login_left .hd_p:nth-child(2) {
    /* color: #58B6CF; */
    font-weight: 600;
    font-size: 12pt;
}
.login_left .hd_p:nth-child(3) {
    /* color: #323A45; */
    font-weight: 600;
    font-size: 12pt;
}
.login_left .hd_p:nth-child(4) {
    /* color: #9386C6; */
    font-weight: 600;
    font-size: 12pt;
}
.login_left .hd_p:nth-child(5) {
    /* color: #58B6CF; */
    font-weight: 600;
    font-size: 12pt;
}
.login_left .hd_p:nth-child(6) {
  /* color: #323A45; */
  font-weight: 600;
  font-size: 12pt;
}

.login_left .hd_p:last-child {
    /* color: #A5A5A5; */
    font-weight: 600;
    font-size: 12pt;
}


.login_left p:nth-child(5) {
    margin-top: 35px;
    margin-left:80px;
}


/* Login_left PART End */


/* Login_Form PART Start */
.login_form {
    width:450px;
    height:auto; 
    background:rgba(0, 0, 0,0.6);
    position: relative;
    margin: 0 auto;
    border-radius:15px;
}

.form_position {
    width: 300px;
    height :350px;
    display:flex;
    flex-flow:column nowrap;
    position: relative;
    top: 230px;
    left: 50%;
    transform: translate(-50%,-50%);
}

.form_position .form_align {
    display:flex;
    flex-flow: row nowrap;
    width:100%;
    height:auto;
    margin-bottom:10px;
}

.form_position .form_align input {    
    width:300px;
    height:50px;
    border:none;
    padding:1px 26px;
    background-color: #fff;
    border-radius:26px;
    font-weight: bold;
    font-size: 11pt;
}
.form_position .form_align input[type=password] {    
    color: #000;
    font-family: "나눔고딕";
}

.form_position .form_align p {
    width :50px;
    height:40px;
    background :#fff;
    position: relative;
    padding-right:15px;
    margin-right:5px;
    background-color: #E5E8ED;
}
.form_position .form_align img {
    justify-content: center;
    align-items: center;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 18px;
    height: 21px;
}
.form_position .form_txt { 
    color:#fff;
    font-weight:600;
    font-size:26px;
    text-align: center;
    padding-bottom:70px;
    padding-top:25px;
    height:128px;
}

.form_position button {
    background:  #9386C6;
    width:100%;
    height:40px;
    color:#fff;
    font-size:16px;
    font-weight:600;
    margin-top:20px;
    cursor: pointer;
}

.form_position a {
  background:  #814fff;
  width:100%;
  height:50px;
  color:#fff;
  font-size:16px;
  font-weight:600;
  margin-top:30px;
  cursor: pointer;
  text-align: center;
  padding-top: 16px;
  border-radius: 26px;
}
.form_position a:hover {
    text-decoration: none;
    color:#000000;    
}
/* Login_Form PART End */


.left a{
    width: 47%;
    height: 100%;
   float: left;
   
  }


  .left a:hover {
    text-decoration: none;
    background:rgba(39, 95, 222, 0.2);  
    color:#fff;
    border-radius:35px;
}

.f_right a{
    width: 47%;
    height: 100%;
    float: right;
   
  }


  .f_right a:hover {
    text-decoration: none;
    background:rgba(39, 95, 222, 0.2);  
    border-radius:35px;
    color:#fff
}

.leftRadius {
    background: rgba(56, 57, 56, 0.7);
    width: 5%;
    height: 11%;
    border-radius: 100%;
    z-index: 0;
    float: left;
    position: absolute;
    float:left
}

.leftRadius a{
    /* background: rgba(56, 57, 56, 0.7); */
    /* width: 97%;
    height: 77%; */
    /* border-radius: 100%; */
    z-index: 1;
    float:left
}
.leftRadius a:hover{
    
    width: 100%;
    height: 77%;
    border-radius: 100%;
    z-index: 1;
    float:left;
    text-decoration: none;
    background:rgba(39, 95, 222, 0.7);  
    border-radius:35px;
    color:#fff
}

.rightRadius {
    border-radius: 35px;
    width: 210px;
    height: 51px;
    background: rgba(20, 28, 36, 0.2)
}

.rightRadius a{
    border-radius: 35px;
    width: 210px;
    height: 51px;
    background: rgba(20, 28, 36, 0.2)
}
.rightRadius a:hover{
    
    width: 100%;
    height: 77%;
    border-radius: 100%;
    z-index: 1;
    float:left;
    text-decoration: none;
    background:rgba(39, 95, 222, 0.2);  
    border-radius:35px;
    color:#fff
}

.rightRadius2 {
    border-radius: 35px;
    width: 447px;
    height: 51px;
    background: rgba(0, 94, 255, 0.5);
}

.rightRadius2 a{
    border-radius: 35px;
    width: 447px;
    height: 51px;
    background: rgba(0, 94, 255, 0.9);
}
.rightRadius2 a:hover{
    
    width: 100%;
    height: 77%;
    border-radius: 100%;
    z-index: 1;
    float:left;
    text-decoration: none;
    background:rgba(0, 94, 255, 0.9);
    border-radius:35px;
    color:#fff
}

.linkFont{
    color:#fff;
    font-family:"PretendardMedium";
    font-size: 12pt;
    padding:17px 10px 15px 62px;
}

.logoFont{
    color: #fff;
    font-size: 73pt;
    font-weight: bold;
    width: 600px;
    height: 160px;
    letter-spacing:-4px;
}

.midFont{
    color: #fff;
    font-size: 11pt;
    font-weight: bold;
    width: 400px;
    height: 70px;
    font-family:"PretendardMedium";
}

.engFont{
    color: #fff;
    font-size: 11pt;
    width:423px;
    height: 24px;
}

/* Login_incorrect modal START */
.white_content {
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    position: absolute;;
    top:0;
    left:0;
}

.white_content > div.white_content_wrap {
    position: absolute;
    z-index:1000; 
    top: 8%; left: 50%;
    transform: translate(-50%,0);
    width:480px; 
    height: 180px;
    padding: 0px;
    background-color:#f0f3fd; 
    display: flex;
    flex-flow:column nowrap;
    box-shadow: 5px 5px 5px #3d3d3d;

	}

.white_content > div.white_content_wrap .top_header {
        width:100%;
        height:40px;
        background:#9386C6;
        color:#fff;
        position: relative;
        display:flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
 }

.top_header p {
     margin-left:1%;
}

.top_header button {
    margin-right:1%;

 
}

.white_content_wrap .incorrect_message {
    width:100%;
    height:100%;
    display: flex;
    flex-flow:row nowrap;
    justify-content: center;
    align-items: center;
}

.white_content_wrap .incorrect_message .inco01 {
    width:30%;
    text-align:center;
    vertical-align: middle;
}


.white_content_wrap .incorrect_message .inco02 {
    width:70%;
    font-size:1.2em;
    
  
}

html .modal_long div.white_content_wrap {height:75% }
.white_content > div.white_content_wrap .top_header .header_l {
    padding-left:2%;
}

 .top_header p {
     font-size:1.3em;
 }

/* The Close Button */


.close {
    color: #fff;
    width:60px;
    height:23px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top:50%;
    right:2%;
    transform: translate(0,-50%);
    padding-top:0; 
    border: 1px solid #fff;
    border-radius: 20px 20px;
    background:none;
    box-sizing: border-box;
 }
  
  .close:hover,
  .close:focus {
    color:#9386C6;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    background:#fff;
  }
  

  button:focus{
    outline:none;
}

/* Login_incorrect modal END */