html,
body {
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-family: PretendardBold,PretendardSemiBold,PretendardRegular,PretendardMedium,PretendardExtraLight,PretendardMedium,NanumSquare, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: #fff;
}

#root {
    height: 100%;
    overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

/* * {  
  height:100%; overflow:hidden;
  box-sizing: border-box;
} */
* {
    font-size: 12px;
    vertical-align: baseline;
    /* font-family: 나눔고딕, NanumGothic, sans-serif; */
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.container {
    margin: 0 auto;
    width: 100%;
}
section {
    box-sizing: border-box;
}

/* Header CSS */
.Header {
    height: 50px;
    background-color: #fff;

    display: block;
    padding: 0;
}
.logo_area {
    float: left;
    width: 200px;
    height: 56px;
    /* background-color: #272531; */
    background-color: #222339;

    color: #fff;
    padding-top: 16px;
    padding-left: 38px;
}
.logo_area > a > img {
    width: 115px;
    height: 28px;
}

.top_area {
    float: left;
    padding-top: 20px;
}
.top_area > a {
    float: left;
    font-weight: Bold;
    font-size: 15px;
    margin: 0px 15px auto;
    /* color: #707070; */

    /* font-family: NanumSquare, 'NanumGothic', sans-serif; */
    font-family: "PretendardMedium", "NanumGothic", sans-serif;

    background-color: #fff;
    padding: 7px 18px;
    border: 1px solid #fff;
    border-radius: 100px;
    color: #fff;
    margin-top: -7px;
    font-weight: bold;
    height: 34px;

    text-decoration: none;
}
.top_area > a:visited {
    color: #707070;
    text-decoration: none;
}
.top_area > a:hover {
    color: #272531;
    text-decoration: none;
}
/* .top_area > a:focus {
  color: #5972C9;
} */
.top_area > a.selected {
    background-color: #3f51b5;
    border: 1px solid #3f51b5;
    color: #fff;

    text-decoration: none;
}



/* AppHeader header_menu_area START --- */
.header_menu_area {
  /* float: left; */
  /* padding-top: 20px; */
  display: flex;
  flex-flow: inherit;
  align-items: center;
  padding-left:0px;
  padding-right:25px;
}
/* AppHeader header_menu_area --- END */



/* AppHeader header_area START --- */
.header_area {
  /* float: left; */
  /* padding-top: 20px; */
  margin-right: auto;
  display: flex;
  flex-flow: inherit;
  align-items: center;
  width:400px;
  height:100px;
  min-width: 400px;
  min-height: 100px;
  float:left;
  
}
.header_area > a {
  float: left;
  font-weight: Bold;
  font-size: 15px;
  /* margin: 0px 15px auto; */
  margin: 0px 15px 0px 0px;
  color: #707070;

  /* font-family: NanumSquare, 'NanumGothic', sans-serif; */
  font-family: "GmarketSansMedium", "NanumGothic", sans-serif;

  background-color: #fff;
  padding: 7px 18px;
  border: 1px solid #fff;
  border-radius: 100px;
  /* color: #fff; */
  /* margin-top: -7px; */
  margin-top: -3px;
  font-weight: bold;
  /* height: 34px; */
  height: 32px;

  text-decoration: none;
}
.header_area > a:visited {
  color: #707070;
  text-decoration: none;
}
.header_area > a:hover {
  color: #272531;
  text-decoration: none;
}


/* .header_area > a:focus {
  color: #5972C9;
} */
.header_area > a.selected {
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  color: #fff;
}
/* AppHeader header_area --- END */



/* AppHeader header_area 2.0v START --- */
.header_area2 {
  margin-right: auto;
  display: flex;
  flex-flow: inherit;

  align-items: center;
  width:500px;
  height:59px;
  min-width: 500px;
  min-height: 59px;
  float:left;
  
}
.header_area2 > a {
  float: left;

  /* font-family: NanumSquare, 'NanumGothic', sans-serif; */
  font-family: "PretendardMedium", "NanumGothic", sans-serif;
  font-size: 18px;
  margin: 0px 15px 0px 0px;
  color: #33353b;

  background-color: #f5f5f5;
  padding: 7px 18px;
  margin-top: -3px;
  height: 32px;
  text-decoration: none;
}
.header_area2 > a:visited {
  color: #33353b;
  text-decoration: none;
}
.header_area2 > a:hover {
  color: #272531;
  text-decoration: none;
}


/* .header_area > a:focus {
  color: #5972C9;
} */
.header_area2 > a.selected {
  background-color: #f5f5f5;
  border-bottom: 4px solid #1815ff;
  font-weight: Bold;
  color: #1815ff;
  height: 65px;
  text-align: center;
  padding-top: 24px;
  font-size: 18px;
  text-decoration: none;
}
/* AppHeader header_area --- END */



/* AppHeader header_info_area START --- */
.header_info_area{
  margin-left: auto;
  display: flex;
  flex-flow: inherit;
  align-items: center; 
  min-width: 0px;
}

.header_info_area_text{
  display: inline-block;   
  min-width: 0px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  word-break:break-all;
}
/* AppHeader header_info_area --- END */


.info_area {
  float: right;
  margin-top: 15px;
  margin-right: 20px;
}
.info_area > a {
  padding: 0px 15px;
}
.info_area > a > img {
  width: 27px;
  height: 27px;
}

.info_area > a:nth-child(2) {
  padding: 0px 15px;
}
.info_area > a:nth-child(2) > img {
  width: 19px;
  height: 19px;
  margin-bottom: 4px;
  margin-top: 3px;
}


.ribbon {
  height: 48px;
  border-top: 1px solid #bfbdcd;
  border-bottom: 1px solid #bfbdcd;
  background-color: #fff;
  margin-left: 170px;
}

.buttonwrapper {
  height: 48px;
  padding: 9px 10px 10px 10px;
  float: right;
  margin-left: 10px;
}
button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  /* width: 70px; */
  height: 33px;
  border: 1px solid #b7bcd6;
  border-radius: 2px;
  background: #fff;
  margin-left: 10px;
  /* padding-right: 10px; */
  font-weight: 600;
  cursor: pointer;
  float: left;
  /* background: #fff;
  border: 1px solid #B7BCD6;
  color: #272531;
  height: 100%;
  outline: none;
  border-radius: 3px!important;
  padding: 0.3em 1.1em 0.3em 0.6em;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer; */
}
button:hover {
    background-color: #e8ecf5;
}

button img {
    margin-right: 6px;
    margin-left: 8px;
}
/* .buttonwrapper > button > span:nth-child(1) {
  display:inline-block;  
  padding-top:1px;
  padding-left:4px;
}
.buttonwrapper > button > span:nth-child(2) {
  display: inline-block;
  padding-top: 4px;
   // padding-top: 1px;  
  padding-left: 4px;
  font-size: 12px;
  font-weight: bold;
} */

/* buttonwrapper button CSS icon */
/* .btn_search { background: url( "/images/button/btn_search.png" ) no-repeat left; width:20px; height:20px; }
.btn_insert { background: url( "/images/button/btn_insert.png" ) no-repeat left; width:20px; height:20px; }
.btn_update { background: url( "/images/button/btn_update.png" ) no-repeat left; width:20px; height:20px; }
.btn_delete { background: url( "/images/button/btn_delete.png" ) no-repeat left; width:20px; height:20px; }
.btn_excel  { background: url( "/images/button/btn_excel.png" )  no-repeat left; width:19px; height:19px; margin: 0px 1px 1px 0px; } */

/* .btn_search { background: url( "/images/button/btn_search.png" ) no-repeat left; width:20px; height:20px; }
.btn_insert { background: url( "/images/button/btn_insert.png" ) no-repeat left; width:20px; height:20px; }
.btn_update { background: url( "/images/button/btn_update.png" ) no-repeat left; width:20px; height:20px; }
.btn_delete { background: url( "/images/button/btn_delete.png" ) no-repeat left; width:20px; height:20px; }
.btn_excel  { background: url( "/images/button/btn_excel.png" )  no-repeat left; width:19px; height:19px; margin: 0px 1px 1px 0px; } */


/* nav menu CSS */
.nav {
    height: 100%;
    width: 200px;
    /* background-color: #272531; */
    background-color: #222339;
    color: white;
    float: left;
    position: fixed;
}
.nav > .nav_area {
    /* position: absolute; */
    top: 65px;
    bottom: 400px;
    height: 100%;
    width: 200px;

    padding-top: 56px;
}
.nav > .nav_area > .top_nav_area {
  display: block;
  font-weight: bold;
  text-align: center;
  
  margin: 28px auto;
  font-size: 16px;
  font-family: 'PretendardMedium', 'NanumGothic', sans-serif;
}
.nav_menu {
    padding-top: 15px;
}
.nav_menu > ul {
    padding-top: 10px;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: 0.5em;
    list-style: none;
    position: relative;
}
.nav_menu > ul > li {
    height: 30px;
}
.nav_menu > ul > li > a {
    color: white;
    padding: 5px 5px 10px 6px;
    font-weight: 600;
    border-bottom: 1px solid #424550;
}


/* nav AppBar Drawer menu CSS START -- */
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap {
  /* color: white; */
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
  color: white;

  padding: 0.5rem 2rem 0.5rem 4rem;  
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  --tw-text-opacity: 1;
  align-items: center;
  /* border-left-width: 2px; */
  color: rgba(55,65,81,var(--tw-text-opacity));
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  width: 100%;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  color: white !important;
  /* color: #343434; */
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-icon,
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option svg {
  color: white !important;
  /* color: inherit !important; */
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-icon,
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option svg:hover {
  color: #3a3a3a !important;
  /* color: inherit !important; */
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap:hover {
    border-color: #3f51b5 !important;
    color: #343434 !important;      
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #e2e8f0;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  font-size: 11pt;    
  font-family: 'PretendardMedium'!important;
  cursor: pointer;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option
  .side-navigation-panel-select-inner-option-wrap
  .side-navigation-panel-select-inner-option-text {
    /* color:white; */
  /* margin-left: -15px !important; */
  margin-left: 0px !important;
  margin-right: -10px !important;
  line-height: 1.5rem !important;
  font-size: 10pt !important;
  font-family:'PretendardMedium' !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option{
  color:#ffffff !important; 
  /* background: #33353b!important; */
  /* padding: 0.5rem 2rem 0.5rem 3.5rem !important; */
  /* padding: 0.5rem 0.5rem 0.5rem 3rem !important; */

  padding-top: 13px !important;
  padding-bottom: 7px !important;
  padding-left: 42px !important;

  
  
  /* border-left-width: 2px; */
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:hover{
  color: #343434 !important;   
  font-family: 'PretendardMedium' !important;
  font-size: 13pt;
}


.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected {
  color: #ed5d3b !important;  
  font-weight: bold;
  font-family: 'PretendardMedium' !important;
  font-size: 13pt;
  /* background-color: #424550 !important; */
  /* border-color: #3f51b5; */
  border-color: #ec4899 !important;
  /* border-left-width: 2px; */
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected:hover {
  color: #343434 !important;  
  background-color: #e8ecf5 !important;
  border-color: #3f51b5 !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:hover {
  color: #343434 !important;  
  background-color: #e8ecf5 !important;
  border-color: #3f51b5 !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-option:hover {
  color: #343434 !important;  
  background-color: #e8ecf5 !important;
  border-color: #3f51b5 !important;
} 

/* .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-icon, .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option svg:hover{
  color: #343434 !important;   
} */
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text{
  /* color:white !important;  */
  color:inherit !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text:hover{
  color:#343434 !important; 
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-icon, .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option svg{
  color:inherit !important; 
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {  
  background-color: #424550 !important;
  color: white !important;
  border-color: #ec4899 !important;
}
/* 
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected:hover {
  border-color: #3f51b5 !important;
  background-color: #e8ecf5 !important;
  color: #343434 !important;    
} */


/* .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap {  
  background-color: #424550 !important;
  color: white !important;
} */
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {
  border-color: #3f51b5;
  background-color: #e8ecf5 !important;
  color: #343434 !important;    
}
/* nav AppBar Drawer menu CSS ---- END */


/* nav banner CSS */
.banner_area {
    left: 5px;
    bottom: 57px;
    width: 100%;
}
.banner_area > a:hover {
  color: #0a0107;
  background-color:#f5ecf280;
  text-decoration: none;
}

.banner_area2 {
  left: 5px;
  bottom: -2px;
  position: fixed;
}
.banner_area2 > a:hover {
color: #0a0107;
background-color:#f5ecf280;
text-decoration: none;
}

/* nav link CSS */
.link_area {
    position: absolute;
    left: 0px;
    bottom: 34px;
    width: 100%;
    height: 143px;
}

/* nav message CSS */
.nav > .message_area {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 34px;
    background-color: #272531;
    color: #fff;
    padding: 8px 45px;
    border-top: 1px solid #bfbdcd;
}
.nav > .message_area > span {
    color: #f5f7fa;
    font-size: 17px;
    font-weight: bold;
}

/* layout CSS */
.layout {
    position: absolute;
    top: 108px;
    left: 170px;
    bottom: 34px;
    /* height: 100%; */
    /* width: 100%; */
    width: calc(100% - 170px); /* Standard */
    width: -webkit-calc(100% - 170px); /* WebKit */
    width: -moz-calc(100% - 170px); /* Firefox */
    width: -ms-calc(100% - 170px); /* MS Explorer */
    width: -o-calc(100% - 170px); /* Opera */
    /* padding: 10px; */

    overflow-y: auto;
    overflow-x: hidden;
}

/* loading CSS */
#loading {
    /* fill: rgb(143, 156, 244);
  height: 64px;
  width: 64px; */
    height: 99px;
    width: 99px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 10;
}


/* content_section CSS */
.main_section {
  display: flex; flex-flow:column nowrap; width:100%; height: 100%; 
  /* background: #E2E7F2; */    
  /* padding: 10px; */
  padding: 0px 0px 0px 0px;
}


/* section CSS */
.section {
    position: absolute;
    top: 108px;
    left: 170px;
    bottom: 34px;
    /* height: 100%; */
    /* width: 100%; */
    width: calc(100% - 170px); /* Standard */
    width: -webkit-calc(100% - 170px); /* WebKit */
    width: -moz-calc(100% - 170px); /* Firefox */
    width: -ms-calc(100% - 170px); /* MS Explorer */
    width: -o-calc(100% - 170px); /* Opera */
    padding: 10px;

    overflow-y: auto;
    overflow-x: hidden;
}

/* footer CSS */
.footer {
    width: 100%;
    bottom: 0px;
    height: 34px;
    background-color: #fff;
    position: absolute;
    border-top: 0.5px solid #bfbdcd;
}
.footer > .nav_area {
    float: left;
    width: 170px;
    /* width: 125px; */
    height: 60px;
    background-color: #272531;
    color: #fff;
    padding: 8px 45px;
}
.footer > .nav_area > span {
    color: #f5f7fa;
    font-size: 17px;
    font-weight: bold;
}

.footer > .contents_area {
    margin-top: 1px;
    border-top: 1px solid #bfbdcd;
    border-bottom: 1px solid #bfbdcd;
}
.footer > .contents_area > .message_area {
    padding-left: 10px;
    padding-top: 7px;
    font-size: 14px;
    float: left;
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.footer > .contents_area > .footer_logo_area {
    float: right;
    margin-top: 7px;
}
.footer > .contents_area > .footer_logo_area > img {
    width: 76px;
    height: 12px;
    margin-top: 3px;
}

.footer > .contents_area > .copyright_area {
    color: #b3b3b3;
    float: right;
    font-size: 14px;
    font-weight: bold;
    margin-top: 7px;
    margin-left: 20px;
    margin-right: 20px;
}

/* 
html {height:100%; font-family:'나눔고딕', 'NanumGothic', sans-serif; 
}
body {height:100%; font-family:'나눔고딕', 'NanumGothic', sans-serif; 
}
article, aside, figcation, figure, footer, header, nav, section {
    display:block;
}
li { list-style:none; }
a  { 
      text-decoration:none;
      color:#3d3d3d;
}
img {
    border:0;
    vertical-align:top;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
input {
    margin:0;
    padding:0;
    border:1px solid 
}

button {
    outline:none;
} */

/* SearchBox CSS */
.k_combo > .MuiOutlinedInput-root {
    border-radius: 0px;
    font-size: 12px;
    font-family: 나눔고딕, NanumGothic, sans-serif;
}
.k_combo > div > select {
    /* width: 100%;
  height: 100%; */
    width: 161px;
    height: 16px;
    background-color: white;
    border: 1px solid #cecdd9;
    border-radius: 0px;
    padding: 7px 5px;
}

.k_combo > div > .MuiSvgIcon-root {
    top: 4px;
    right: 4px;
    width: 1.2em;
    height: 1.2em;
    font-size: 1.7rem;
}

/* SearchBox 입력데이터 CSS */
.k_combo2 > .MuiOutlinedInput-root {
  border-radius: 0px;
  font-size: 12px;
  font-family: 나눔고딕, NanumGothic, sans-serif;
}
.k_combo2 > div > select {
  /* width: 100%;
height: 100%; */
  width: 80px;
  height: 16px;
  background-color: white;
  border: 1px solid #cecdd9;
  border-radius: 0px;
  padding: 7px 5px;
}

.k_combo3 > div > select {
  /* width: 100%;
height: 100%; */
  width: 50px;
  height: 16px;
  background-color: white;
  border: 1px solid #cecdd9;
  border-radius: 0px;
  padding: 7px 5px;
}
.k_combo3.readonly > div > select {
  /* width: 100%;
height: 100%; */
  width: 50px;
  /* height: 16px; */
  /* background-color: white; */
  /* border: 1px solid #cecdd9; */
  height: 18px;
  background-color: #f1f3f7;
  border: 0px solid #cecdd9;
  border-radius: 0px;
  padding: 7px 5px;
}

/*rom3z 회사구분 select 추가*/
.k_combo4 > .MuiOutlinedInput-root {
  border-radius: 0px;
  font-size: 12px;
  font-family: 나눔고딕, NanumGothic, sans-serif;
}
.k_combo4 > div > select {
  
  width: 193px;
  height: 16px;
  background-color: white;
  border: 1px solid #cecdd9;
  border-radius: 0px;
  padding: 7px 5px;
  min-width: 192px;
}
.k_combo4 > div > .MuiSvgIcon-root {
  top: 4px;
  right: 4px;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.7rem;
}

.k_combo2 > div > .MuiSvgIcon-root {
  top: 4px;
  right: 4px;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.7rem;
}



/* InputBox 입력데이터 CSS */
.k_input2 > .MuiOutlinedInput-root {
  border-radius: 0px;
  font-size: 12px;
  font-family: 나눔고딕, NanumGothic, sans-serif;
}

.k_input2 > div > input {
  /* width: 100%;
height: 100%; */
  width: 100px;
  height: 18px;
  background-color: white;
  border: 1px solid #cecdd9;
  border-radius: 0px;
  /* padding-left: 5px; */
  padding: 6px 5px;
}

/* InputBox CSS */
.k_input2 > .MuiOutlinedInput-root {
    border-radius: 0px;
    font-size: 12px;
    font-family: 나눔고딕, NanumGothic, sans-serif;
}
.k_input > div > input {
    /* width: 100%;
  height: 100%; */
    width: 188px;
    height: 18px;
    background-color: white;
    border: 1px solid #cecdd9;
    border-radius: 0px;
    /* padding-left: 5px; */
    padding: 6px 5px;
}
.k_input > div > input:read-only {
  background-color: #f1f3f7;
}

/* Modal CSS */
.MuiDialog-container {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}
.MuiDialogTitle-root {
    background-color: #9386c6;
    color: white;
    font-weight: bold;
    font-family: "나눔고딕", "NanumGothic", sans-serif;

    padding: 10px 16px !important;
}
.dialog_title_custom{
  background-color: #1C1E25 !important;
}
.dialog_title_custom h2{ line-height: 2.2;}
.dialogAction_algc{
  justify-content: center !important;
}
.mui_r{
  color: #FF0505 !important;
  border: 1px solid #FF0505 !important;
  border-radius: 0px !important;
  padding: 19px 20px !important;
  margin: 5px !important;
  width: 152px;
  height: 39px;
  font-family: "PretendardExtraLight";
  font-size : 18px
}
.mui_r:hover{
  background-color: none;
}
.mui_b{
  color: #0057F4 !important;
  border: 1px solid #0057F4 !important;
  border-radius: 0px !important;
  padding: 19px 20px !important;
  margin: 5px !important;
  width: 152px;
  height: 39px;
  font-family: "PretendardExtraLight";
  font-size : 18px
}
.mui_b:hover{
  background-color: none;
}
.Modal > .MuiSvgIcon-root {
    width: 1em !important;
    height: 1em !important;
    font-size: 2rem !important;
    top: 3px !important;
    right: 4px !important;
    /* padding-top: 2px !important; */
}
.Modal > .MuiIconButton-label {
    color: white;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}

.MuiDialogTitle-root > .MuiIconButton-root {
    /* padding-bottom: 3px; */
    color: #fff !important;

    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.MuiDialogContent-root {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}
.Modal > .MuiButton-label {
    font-weight: bold;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}
/* .MuiTypography-root .MuiTypography-gutterBottom-root { font-weight: bold; font-size: 1.25rem !important; font-family: '나눔고딕', 'NanumGothic', sans-serif; } */
.Modal > .MuiTypography-body1 {
    font-weight: bold;
    font-size: 1.25rem !important;
    font-family: "나눔고딕", "NanumGothic", sans-serif;
}

/* Header Menu CSS */
.MuiButtonBase-root.MuiIconButton-root {
    padding: 7px;
    /* margin-left: 5px; */
    margin-top: -2px;
}
.MuiMenu-paper {
    top: 45px !important;
}
.MuiMenu-list {
    padding: 0px 5px;
}
.MuiListItemIcon-root {
    min-width: 46px !important;
}

/* Check Box */
.checks input[type="checkbox"] {
    font-size: 1em;
    width: 1.25em; /* 너비 설정 */
    height: 1.25em; /* 높이 설정 */
    vertical-align: middle;
}
.checks input[type="checkbox"] + label {
    /* 라벨 텍스트 크기와 수직 정렬 맞춤 */
    font-size: 1.125em;
    vertical-align: middle;
}

/* Radio Box */
/* .radios {position: relative;}
.radios input[type="radio"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }
.radios input[type="radio"] + label { display: inline-block; position: relative; padding-left: 30px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; }
.radios input[type="radio"] + label:before { content: ''; position: absolute; left: 0; top: -4px; width: 21px; height: 21px; text-align: center; background: #fafafa; border: 1px solid #cacece; border-radius: 100%; box-shadow: 0px 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05); }
.radios input[type="radio"] + label:active:before, .radios input[type="radio"]:checked + label:active:before { box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1); }
.radios input[type="radio"]:checked + label:before { background: #E9ECEE; border-color: #adb8c0; }
.radios input[type="radio"]:checked + label:after { content: ''; position: absolute; top: 1px; left: 5px; width: 13px; height: 13px; background: #99a1a7; border-radius: 100%; box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3); } */
.radios input[type="radio"] {
    font-size: 1em;
    width: 1.25em; /* 너비 설정 */
    height: 1.25em; /* 높이 설정 */
    vertical-align: middle;
}
.radios input[type="radio"] + label {
    /* 라벨 텍스트 크기와 수직 정렬 맞춤 */
    font-size: 1.125em;
    vertical-align: middle;
}


/* Tab react-dyn-tabs CSS START -- */
.rc-dyn-tabs-panellist {
  padding: 0 !important;
  height: calc(100% - 30px) !important;
}
.rc-dyn-tabs-panellist.rc-dyn-tabs-ltr .rc-dyn-tabs-panel {
  height: 100%;
}

.rc-dyn-tabs-tablist{  
  background-color: #e6e6e6 !important;  
  border-top: 1px solid #BFBDCD !important;  
  /* height: 34px; */
}
.rc-dyn-tabs-tablist .rc-dyn-tabs-tab.rc-dyn-tabs-selected{  
  background-color: #e6e6e6 !important;  
  height: 33px !important;
}
.rc-dyn-tabs-tablist .rc-dyn-tabs-tab.rc-dyn-tabs-selected>.rc-dyn-tabs-title{
  /* border-width: 2px !important;*/
  border-bottom: 2px !important; 
  
  border-color: #BFBDCD #BFBDCD #fff #BFBDCD !important;
}
/* Tab react-dyn-tabs CSS ---- END */



/* Print */
@media all {
    .printArea {
        display: none;
    }
}

@media print {
    .printArea {
        display: block;
    }
}

