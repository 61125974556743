/*
html,
body {
  height:100%; overflow:hidden;
  padding: 0;
  margin: 0;
  font-family: NanumSquare, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;    
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}


* {
    font-size: 12px;
    vertical-align: baseline;
    font-family: 나눔고딕, NanumGothic, sans-serif;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

*/
html, body {
  height:100%; overflow:hidden;
}

/* Header START */ 

.side_section {
    
  width:170px; min-width:170px; 
  height:100%;
  background:#272531;

}

.side_section .menuside_left {
  width:100%; max-width:170px;
  height:100%;
  
  
}

.side_section .menuside_left img {
  height:100%;
  width:100%;

}


.main_section {
  display: flex;
  flex-flow: column nowrap;

  width: calc(100% - 200px);  
  margin-left: 200px;
  height:100%;
}


header {
  
  width:100%;

  height:50px;
  display:flex;
  flex-flow:row nowrap;
  align-items: flex-start;

 
  background:#fff;
}


header nav {
  display: flex;
  flex-flow:row nowrap;
  justify-content: flex-start;
  align-items: center;

  /* flex:8; */

  height: 100%;;
}


header nav ul {
  width:360px;

  display: flex;
  flex-flow:row nowrap;
  justify-content: center;
  align-items: center;

}

header nav ul li {
  width:120px;

  display: flex;
  flex-flow:row nowrap;
  justify-content: center;
  align-items: center; 
}

header nav ul li .nav_txt {
  color:#707070;
  font-size:16px;
  font-weight: 400;
}

header nav ul li .nav_txt:hover {
  color:#5972C9;
  font-size:16px;
  font-weight: 600;
}





header .hd_right {
  display: flex;
  flex-flow:row nowrap;
  justify-content: center;
  align-items: center;

  flex:1;
  width:200px;
  height:100%;


  position: relative;
}

header .hd_right .user_box {
  width:120px;
  height:100%;

  display: flex;
  flex-flow:row nowrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  right:10px;
}


header .hd_right .user_box li {
  width:60px;
  height:100%;

  
  display: flex;
  flex-flow:row nowrap;
  justify-content: center;
  align-items: center;
}









/* Header END */ 